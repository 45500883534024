define("discourse/plugins/discourse-blizzard-plugin/admin/templates/components/category-realm-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label>{{i18n "admin.blizzard.category_realm"}}</label>
  <div class="controls">
    {{combo-box
      value=this.category.custom_fields.is_realm
      nameProperty="name"
      content=this.content
      onChange=(action (mut this.category.custom_fields.is_realm))
    }}
  </div>
  */
  {
    "id": "iUU32jzj",
    "block": "[[[10,\"label\"],[12],[1,[28,[35,0],[\"admin.blizzard.category_realm\"],null]],[13],[1,\"\\n\"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"value\",\"nameProperty\",\"content\",\"onChange\"],[[30,0,[\"category\",\"custom_fields\",\"is_realm\"]],\"name\",[30,0,[\"content\"]],[28,[37,2],[[30,0],[28,[37,3],[[30,0,[\"category\",\"custom_fields\",\"is_realm\"]]],null]],null]]]]],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"combo-box\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/admin/templates/components/category-realm-checkbox.hbs",
    "isStrictMode": false
  });
});