define("discourse/plugins/discourse-blizzard-plugin/admin/templates/components/b-notification-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.employee_flag}}
    <label>
      <div class="b-notification-message-label">
        {{i18n "admin.blizzard.notification_message"}}
      </div>
    </label>
    {{textarea
      value=this.message
      class="b-notification-message"
      placeholder=(i18n "admin.blizzard.notification_message_placeholder")
    }}
  {{else}}
    {{i18n "admin.blizzard.notification_message_not_employee"}}
  {{/if}}
  */
  {
    "id": "8Sbcu5N3",
    "block": "[[[41,[30,0,[\"currentUser\",\"employee_flag\"]],[[[1,\"  \"],[10,\"label\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"b-notification-message-label\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"admin.blizzard.notification_message\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"value\",\"class\",\"placeholder\"],[[30,0,[\"message\"]],\"b-notification-message\",[28,[37,1],[\"admin.blizzard.notification_message_placeholder\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],[\"admin.blizzard.notification_message_not_employee\"],null]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"i18n\",\"textarea\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/admin/templates/components/b-notification-message.hbs",
    "isStrictMode": false
  });
});