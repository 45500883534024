define("discourse/plugins/discourse-blizzard-plugin/admin/templates/connectors/admin-user-below-names/blizzard-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="display-row bnet-id">
    <div class="field">
      Battle.net ID
    </div>
    <div class="value">
      {{dash-if-empty this.user.bnet_id}}
    </div>
  </div>
  
  <div class="display-row battletag">
    <div class="field">
      BattleTag
    </div>
    <div class="value">
      {{dash-if-empty this.user.battletag}}
    </div>
  </div>
  */
  {
    "id": "WcfEQW/G",
    "block": "[[[10,0],[14,0,\"display-row bnet-id\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    Battle.net ID\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"user\",\"bnet_id\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"display-row battletag\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n    BattleTag\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"value\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[[30,0,[\"user\",\"battletag\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"dash-if-empty\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/admin/templates/connectors/admin-user-below-names/blizzard-fields.hbs",
    "isStrictMode": false
  });
});